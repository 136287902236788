/* eslint-disable */
import { all, takeEvery, put, call } from 'redux-saga/effects';
import { notification } from 'antd';
import axios from 'axios';
import merge from 'lodash/merge';

export function isDevEnv() {
  return process.env.NODE_ENV == 'development';
}
export function getImageUrl(path) {
  return `${process.env.REACT_APP_CDN}/${path}`;
}
export function getImageById(id, small) {
  if (!id) return null;
  return `${process.env.REACT_APP_CDN}/r/${id}_${small ? '100' : '428'}.jpg?v=${new Date().getTime()}`;
}
export function getCompanyLogo(path, v) {
  return path ? getImageUrl(path + '?v=' + v) : 'resources/images/temkinopticians-logo.png';
}
export default getImageUrl;

export const l10n = {
  loading: 'Loading',
  error: 'Something went wrong',
  true: 'Yes',
  false: 'No',
  confirm: 'Are you sure?',
  api: {
    'code-2': 'Permission denied!',
    'code-10': 'Empty model!',
    'code-11': 'Invalid model!',
    'code-12': 'Not found!',
    code10: 'Deleted!',
    code11: 'Saved!',
    code12: 'Created!',
    code100: 'Go to login',
    code101: 'Refresh token',
    code103: 'Invalid credentials',
    code200: 'Invalid credentials',
    code201: 'User not found',
    code202: 'Your old password is wrong!',
    code203: 'Invalid code!',
    code204: 'User not verified!',
    code205: 'Your password has been changed!',
    code206: 'This email address is already in use! You can reset your password if you do not remember it.',
    code207: 'Your email address is waiting to be verified! Please check your inbox to verify your email address.',
    code300: 'This verification code has been expired. Please use the RESEND form.',
    code301: 'This code has been blocked due to failed attempts. Please use the RESEND form.',
    code302: 'This email has already been verified.',
    code303: 'Your account has been created succesfully! Please check your inbox to verify your email address.',
    code304: 'Verification code has been sent! Please check your inbox to verify your email address.',
    code305: 'A code has been sent to your email! Please check your inbox to reset your password.',
    code1700: 'Add an image!',
    code1701: "Image couldn't upload!",
    code1702: 'Invalid image!',
    code1703: 'Your verification code has been sent by email!',
    code1704: "Campaign email couldn't send!",
    code1707: 'Campaign email has been sent!',
    code1708: "User is created however verification email couldn't send. Please contact us!",
    code2000: 'Default card can not be removed!',
    code2001: 'Please add a payment method!',
    code2102: 'Only upgrade available!',
    code2103: 'You have upgraded your plan!',
    code2104: 'You have downgraded your plan!',
    code2105: 'Your plan has started!',
    code2106: 'Your plan has cancelled!',
    code2107: 'Trial plan is already defined!',
    code2108: 'Ad limit is exceeded! Please upgrade your plan!',
    code2109: 'SubscribeIdleCannotCancel!',
    code2110: 'Your current plan is paused for temporary due to incompleted payment. Please contact us!',
  },
};

export function processResponse(response, showGeneralError) {
  let msg = '';
  let title = response.message;
  let onkaData = response;
  // console.log('processResponse', response, response.message);
  if (response && response.isAxiosError && response.response) {
    const { data } = response.response;
    if (data) onkaData = data;
    if (response.response.status < 200 || response.response.status >= 300) {
      if (data && (data.code == 100 || data.code == 101)) {
        put({
          type: 'user/LOGOUT',
        });
        return false;
      }
    }
  }
  if (response && (response.error || response.isAxiosError)) {
    var errors;
    if (response.error) {
      const { data } = response.error;
      errors = data?.errors;
      msg = data.title || '';
    } else if (response.isAxiosError && response?.response?.data?.errors) {
      title = response.response.data.title;
      errors = response.response.data.errors;
    }

    if (errors) {
      for (const key in errors) {
        for (let i = 0; i < errors[key].length; i++) {
          msg += '\n' + errors[key][i];
        }
      }
    }
  }
  if (onkaData && onkaData.success && !onkaData.code) {
    return true;
  }
  if (onkaData.code == 100 || onkaData.code == 101) {
    put({
      type: 'user/LOGOUT',
    });
    return false;
  }
  if (l10n.api[`code${onkaData.code}`]) msg = l10n.api[`code${onkaData.code}`];
  if (msg || title) {
    if (onkaData.message != (title || msg)) onkaData.message = (title || msg) + ' ' + (onkaData.message || '');
    if (onkaData.success) {
      notification.success({
        message: onkaData.message,
        description: title ? msg : '',
        duration: 5,
      });
    } else {
      notification.error({
        message: onkaData.message,
        description: title ? msg : '',
        duration: 0,
      });
    }
  } else if (showGeneralError && !onkaData.success) {
    notification.error({
      message: 'Could not load data!',
      description: 'System could not communicate properly with the endpoints!',
      duration: 0,
    });
  }
  return onkaData.success;
}

function getFormDataKey(key0, key1) {
  return !key0 ? key1 : `${key0}[${key1}]`;
}
export function convertModelToFormData(model, key, frmData) {
  let formData = frmData || new FormData();

  if (!model) return formData;

  if (model instanceof Date) {
    formData.append(key, model.toISOString());
  } else if (model instanceof Array) {
    model.forEach((element, i) => {
      convertModelToFormData(element, getFormDataKey(key, i), formData);
    });
  } else if (typeof model === 'object' && !(model instanceof File)) {
    for (let propertyName in model) {
      if (!model.hasOwnProperty(propertyName) || !model[propertyName]) continue;
      convertModelToFormData(model[propertyName], getFormDataKey(key, propertyName), formData);
    }
  } else {
    formData.append(key, model);
  }

  return formData;
}

export function ApiError(reason) {
  this.reason = reason;
  return this;
}

export function processError(reason) {
  console.log(reason);

  // if (reason instanceof ServiceHttpStatusResult) {
  //   throw new ApiError(reason);
  // }

  var result = {
    status: 0,
    statusText: reason,
    success: reason.response?.data?.success || false,
    code: reason.response?.data?.code,
    message: reason.response?.data?.message,
    value: reason.response?.data?.value,
    total: reason.response?.data?.total,
  };

  if (reason.response) {
    var json = reason.toJSON();
    console.log('error detail json', json);
    console.log('error detail response', reason.response);
    result.status = reason.response.status;
    result.statusText = reason.response.statusText || reason.response.data;
    //result.message = json.message;
    if (reason.response.data?.errors) {
      result.message = reason.response.data.title || '';
      for (const key in reason.response.data.errors) {
        for (let i = 0; i < reason.response.data.errors[key].length; i++) {
          result.message += '\n' + reason.response.data.errors[key][i];
        }
      }
    }
  } else if (reason instanceof Error) {
    let error = reason;
    result.message = error.message;
    result.statusText = error.stack;
  }

  if (result.status < 200 || result.status >= 300) {
    //throw new ApiError(result);
  }

  return result;
}

const ApiResponse = { success: false, value: null };

/**
 * API Call
 * @param {import('axios').AxiosRequestConfig} config
 * @returns
 */
export function apiCall(config) {
  return (
    axios(
      merge(
        {
          method: 'POST',
          baseURL: process.env.REACT_APP_API,
          timeout: 300000,
          headers: {
            token: localStorage.getItem('token'),
          },
        },
        config,
      ),
    )
      .then(async (response) => {
        // console.log('res', response);
        if (config.responseType == 'blob') {
          if (response?.data?.type != 'application/json') {
            return { value: response?.data, success: true };
          }
          var json = JSON.parse(await response?.data.text());
          console.log('blob json', json);
          if (processResponse(json, true)) {
            return json;
          }
          return { success: false, ...json };
        }

        if (processResponse(response?.data, true)) {
          return response.data;
        }
        return { ...response?.data, success: false };
      })
      //.catch(processError);
      .catch((reason) => {
        var result = processError(reason);
        processResponse(result);
        return result;
      })
  );
}
function getLogBase(eventName) {
  return {
    event: eventName,
    userId: localStorage.getItem('uid'),
  };
}
export function gtmLog(obj) {
  if (!window.dataLayer) return;
  if (isDevEnv()) return;
  //console.log('GTM', obj, process.env.NODE_ENV);
  window.dataLayer.push(obj);
}
export function gtmLogPageChanged(path) {
  gtmLog({
    ...getLogBase('Pageview'),
    pagePath: path,
  });
}
export function gtmLogLogin() {
  gtmLog({
    ...getLogBase('Login'),
  });
}
export function gtmLogLogout() {
  gtmLog({
    ...getLogBase('Logout'),
  });
}

export function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

export const isEmail = (email) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i.test(email);
export const decodeBase64 = (str) => {
  try {
    return Buffer.from(str, 'base64').toString('utf8');
  } catch (error) {}
  return null;
};
export const encodeBase64 = (str) => {
  try {
    return Buffer.from(str, 'utf-8').toString('base64');
  } catch (error) {}
  return null;
};
