import { Button, Col, Image, Row, Tooltip, Modal } from 'antd';
import React, { useState, useEffect, useRef } from 'react';

const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
const MIN_IMAGE_WIDTH = 3000; //3000;
const MIN_IMAGE_HEIGHT = 3000; //3000;
const MAX_IMAGE_SIZE = 30000000;

export const validateImages = (files) => {
  return Promise.all(
    files.map((file, index) => {
      return validateImage(file).catch(err => err);
    }),
  );
};

export const validateImage = (file) => {
  return new Promise((resolve, reject) => {
    if (!file || !allowedExtensions.exec(file.name)) {
      reject(['Invalid file type', file?.name]);
      return;
    }

    if (file.size > MAX_IMAGE_SIZE) {
      reject(['Maximum image size is 30Mb]', file.name]);
      return;
    }

    var img = document.createElement('img');

    img.onload = function () {
      if (this.width >= MIN_IMAGE_WIDTH || this.height >= MIN_IMAGE_HEIGHT) {
        resolve(file);
      } else {
        reject(['Your images must be at least 3000px on the longest edge', file.name]);
      }
    };

    var reader = new FileReader();
    reader.onloadend = function (ended) {
      img.src = ended.target.result;
    };
    reader.readAsDataURL(file);
  });
};
