import { all, takeEvery, put, call } from 'redux-saga/effects';
import { apiCall } from 'services/utils';
import { history } from 'index';
import actions from './actions';

export function* GET_FEATURES({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });
  const response = yield call(() => apiCall({ method: 'GET', url: '/payment/feature/list', data: payload }));
  if (response.success) {
    // yield put({
    //   type: 'user/LOAD_CURRENT_ACCOUNT',
    // });
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
      features: response.value,
    },
  });
}

export function* SUBSCRIBE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });
  const response = yield call(() => apiCall({ url: '/payment/feature/subscribe', data: payload }));
  if (response.success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    });
  } else if(response.code == 2001){
    yield call(history.push, {
      pathname: '/users/payments/add',
      state: history.location && history.location.state,
    });
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  });
}
export function* CANCEL({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });
  const response = yield call(() => apiCall({ url: '/payment/feature/cancel', data: payload }));
  if (response.success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    });
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  });
}

export function* CREATE_SETUP_INTENT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });
  const response = yield call(() => apiCall({ url: '/payment/stripe/create-setup-intent', data: payload }));
  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        setupIntent: response.value,
      },
    });
    return;
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  });
}
export function* CARD_LIST({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });
  const response = yield call(() => apiCall({ url: '/payment/card/list', data: payload }));
  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        cards: response.value,
      },
    });
    return;
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  });
}
export function* CARD_REMOVE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });
  const response = yield call(() => apiCall({ url: '/payment/card/remove', data: payload }));
  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        cards: response.value,
      },
    });
    return;
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  });
}
export function* CARD_MAKE_DEFAULT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });
  const response = yield call(() => apiCall({ url: '/payment/card/makedefault', data: payload }));
  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        cards: response.value,
      },
    });
    return;
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  });
}
export function* PAYMENT_HISTORY_LIST({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });
  const response = yield call(() => apiCall({ url: '/payment/charge/list', data: payload }));
  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        paymentHistory: response.value,
      },
    });
    return;
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  });
}
export function* SUBSCRIPTION_LIST({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });
  const response = yield call(() => apiCall({ method: 'GET', url: '/payment/subscription/list', data: payload }));
  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        subscriptionList: response.value,
      },
    });
    return;
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  });
}
export function* INVOICE_LIST({ payload }) {
  const response = yield call(() => apiCall({ url: '/payment/invoice/list', data: payload }));
  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        invoiceList: response.value,
      },
    });
    return;
  }
}
export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_FEATURES, GET_FEATURES),
    takeEvery(actions.SUBSCRIBE, SUBSCRIBE),
    takeEvery(actions.CANCEL, CANCEL),
    takeEvery(actions.CREATE_SETUP_INTENT, CREATE_SETUP_INTENT),
    takeEvery(actions.CARD_LIST, CARD_LIST),
    takeEvery(actions.CARD_REMOVE, CARD_REMOVE),
    takeEvery(actions.CARD_MAKE_DEFAULT, CARD_MAKE_DEFAULT),
    takeEvery(actions.PAYMENT_HISTORY_LIST, PAYMENT_HISTORY_LIST),
    takeEvery(actions.SUBSCRIPTION_LIST, SUBSCRIPTION_LIST),
    takeEvery(actions.INVOICE_LIST, INVOICE_LIST),
  ]);
}
