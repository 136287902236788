import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import Loadable from 'react-loadable';

import Loader from 'components/LayoutComponents/Loader';
import IndexLayout from 'layouts';
import NotFoundPage from 'pages/404';

const loadable = (
  loader, //Loader;
) =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  });

const routes = [
  // Godin Routes
  {
    path: '/debug',
    component: loadable(() => import('pages/debug')),
    exact: true,
  },
  {
    path: '/login',
    component: loadable(() => import('pages/user/login')),
  },
  {
    path: '/register',
    component: loadable(() => import('pages/user/register')),
    exact: true,
  },
  {
    path: '/forgot-password',
    component: loadable(() => import('pages/user/forgot')),
  },
  {
    path: '/forgot-password-final',
    component: loadable(() => import('pages/user/forgot-final')),
  },
  {
    path: '/verification',
    component: loadable(() => import('pages/user/verification')),
  },
  {
    path: '/verification-resend',
    component: loadable(() => import('pages/user/verification-resend')),
  },
  {
    path: '/dashboard',
    component: loadable(() => import('pages/home')),
  },
  {
    path: '/terms',
    component: loadable(() => import('pages/terms')),
    exact: true,
  },

  // users routes
  {
    path: '/welcome',
    component: loadable(() => import('pages/welcome')),
  },
  {
    path: '/users/feedback',
    component: loadable(() => import('pages/user/feedback')),
  },
  {
    path: '/payments',
    component: loadable(() => import('pages/payments/index')),
  },
  {
    path: '/users/change-password',
    component: loadable(() => import('pages/change-password/index')),
  },
  {
    path: '/your-details',
    component: loadable(() => import('pages/yourDetails/index')),
  },
  {
    path: '/media-library',
    component: loadable(() => import('pages/mediaLibrary')),
  },
];
class Router extends React.Component {
  render() {
    const { history } = this.props;
    return (
      <ConnectedRouter history={history}>
        <IndexLayout>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/login" />} />
            {routes.map((route) => (
              <Route path={route.path} component={route.component} key={route.path} exact={route.exact} />
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </IndexLayout>
      </ConnectedRouter>
    );
  }
}

export default Router;
